import { useField, useForm } from 'vee-validate'
import { account, password, isAgree } from '@/utils/vee-validate-schema'
// import { loginByAccountAndPassword } from "@/api/user";
import useLoginAfter from '@/hooks/login/useLoginAfter'

export default function useAccountFormValidate() {
  // 创建账号登陆表单验证
  const { handleSubmit: accountFormHandleSubmit } = useForm({
    // 指定表单验证规则
    validationSchema: {
      account,
      password,
      isAgree,
    },
  })
  // 对用户名进行验证（使用account规则）
  const { value: accountField, errorMessage: accountError } = useField('account')
  // 对密码进行验证（使用password规则）
  const { value: passwordField, errorMessage: passwordError } = useField('password')
  // 对协议进行验证（使用isAgree规则）
  const { value: accountIsAgreeField, errorMessage: accountIsAgreeError } = useField('isAgree')

  // 默认账号密码
  // accountField.value = "";
  // passwordField.value = "";

  // 登录成功/失败 操作方法
  const { loginSuccessFn, loginFailedFn } = useLoginAfter()

  //#region 账号登录
  const onAccountFormSubmit = accountFormHandleSubmit(value => {
    // loginByAccountAndPassword(value).then(loginSuccessFn).catch(loginFailedFn);
    if (value.account !== 'admin88' || value.password !== '123456') {
      return loginFailedFn()
    }

    let data = {
      result: {
        ...value,
        id: 10080,
        avatar: 'https://img.yzcdn.cn/vant/cat.jpeg',
        nickname: 'admin88',
        mobile: '18888888888',
        token: 'admin88',
      },
    }

    loginSuccessFn(data)
  })
  //#endregion

  return {
    accountField,
    accountError,
    passwordField,
    passwordError,
    accountIsAgreeField,
    accountIsAgreeError,
    onAccountFormSubmit,
  }
}
